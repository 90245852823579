@import "../styles/Variables.scss";

.link-list {
	color: inherit;
	
	.link-item {
		font-weight: 400;
		width: 100%;
		display: block;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			opacity: 1;
		}

		&.active-option {
			opacity: 1;
			font-weight: $fw-semi-bold;
			color: $colour-blue;
		}
	}
}

