@import "../styles/Variables.scss";

.contact-form-container {
	padding-bottom: 100px;
	padding-top: 50px;
	
	@media screen and (min-width: $breakpoint-md) {
		padding-bottom: 100px;
		padding-top: 100px;
	}

	&.modal-form {
		padding: 0;
	}
}

.contact-form {
	.ts-input-field-wrapper {
		margin-bottom: 30px;
	}

	@media screen and (min-width: $breakpoint-lg) {
		.ts-input-field-wrapper {
			margin-bottom: 50px;
		}
	}
}

.submitting-form {
	opacity: 0.6;
	pointer-events: none;
}