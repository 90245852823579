@import "../../../styles/Variables.scss";

.ts-nav-bar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	transition: 300ms ease;
	padding-top: 50px;
	padding-bottom: 50px;
	overflow: visible;

	&.page-scrolled {
		padding-top: 30px;
        padding-bottom: 16px;
		box-shadow: 0 1px 5px rgba($colour-blue-dark, 0.1);

		.link-item {
			font-size: 15px;
		}
	}
}

.ts-nav-bar-links {
	display: flex;
	align-items: center;
	
	.link-item {
		font-weight: $fw-medium;
		font-size: 17px;
		transition: 300ms ease;
		margin-bottom: 0;
		margin-right: 54px;
		text-decoration: none;
		width: max-content;
		transition: 200ms ease-in-out;
		cursor: pointer;
		z-index: 9;

		&.active-option {
			color: $colour-font-main;
			font-weight: $fw-bold;
			position: relative;
			
			&:after {
				content: "";
				width: 100%;
				height: 4px;
				position: absolute;	
				bottom: -16px;
				left: 0;
				border-radius: 4px;
				background-color: $colour-blue;

			}

			&:hover {
				color: inherit;
				padding-bottom: 0;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			color: $colour-blue;
		}

		&.nav-bar-product-menu {
			&:hover {
				padding: 0;
			}
		}
	}

	.MuiButton-root {
		width: 100px;
	}
}