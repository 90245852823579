@import "../styles/Variables.scss";

.integrations-block {
	padding: 10px 32px;
	width: auto;
	display: inline-flex;
	background-color: #fff;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 32px;
	row-gap: 24px;
}

