@import "../styles/Variables.scss";

#wealth {
	.centred-hero-image {
		margin-top: 0;
		
		img {
			transform: scale(1);
		}

		@media screen and (min-width: $breakpoint-md) {
			margin-top: 120px;
		}
	}

	.container {
		width: 100%;
		max-width: 1600px;
	}
}