@import "../styles/Variables.scss";

.homepage-hero {
	display: flex;
	align-items: center;
	
	@media screen and (min-width: $breakpoint-xl){
		height: 60vh;
		position: relative;
	}

	@media screen and (min-height: 1100px){
		height: auto;
		margin-bottom: inherit;	
	}
}