@import "../styles/Variables.scss";

.scroll-fade-in-section {
	opacity: 0;
	transform: translateY(2vh);
	visibility: hidden;
	transition: opacity 0.8s ease-out, transform 1.1s ease-out;
	will-change: opacity, visibility;
	
	&.scroll-fade-faster {
		transition: opacity 0.4s ease-out, transform 0.8s ease-out;
	}

	&.scroll-fade-slower {
		transition: opacity 1s ease-out, transform 1.3s ease-out;
	}

	&.scroll-no-transform {
		transform: scale(0.9);
	}
	
	&.scroll-fade-in-left {
		transform: translateX(20px);
	}

	&.scroll-fade-in-right {
		transform: translateX(-20px);
	}
  }

  .scroll-fade-in-section.is-visible {
	opacity: 1;
	transform: none;
	visibility: visible;
}