@import "./styles/bootstrap-overrides.scss";
@import "./styles/Variables.scss";
@import "./styles/Typography.scss";
@import "./styles/Inputs.scss";
@import "./styles/Animations.scss";

body, * {
	font-family: $font-primary;
	font-style: normal;
	font-weight: 100;
	font-size: 16px;

	&::selection {
		background-color: rgba($colour-blue, 0.3);
	}
}

a {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	text-decoration: none;
	transition-duration: 100ms;

	&:hover {
		color: $colour-blue;
		font-size: inherit;
		font-weight: inherit;
		text-decoration: none;
	}
}

body {
	color: $colour-font-main;
	background-color: #fff;
}

hr {
	width: 100%;
	height: 1px;
	border: none;
	background-color: $colour-divider;
	margin: 10px 0;
}

.master-container {
	min-height: 100vh;
	position: relative;
	padding-bottom: $footer-height-xs;

	@media screen and (min-width: $breakpoint-md){
		padding-bottom: $footer-height-md;
	}

	@media screen and (min-width: $breakpoint-lg){
		padding-bottom: $footer-height-lg;
	}
}

.main-content-container {
	padding-top: 60px;

	@media screen and (min-width: $breakpoint-lg){
		padding-top: 120px;
	}
}

.cursor-pointer {
	cursor: pointer;
}