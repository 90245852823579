@import "../../styles/Variables.scss";

.logo-wrapper {
	max-width: 60px;
	height: 48px;
	display: flex;
	align-items: center;

	img {
		transition: 200ms ease;
		max-width: 100%;
		height: auto;
		max-height: 100%;
	}
	&:hover {
		img {
			transform: scale(1.1);
		}
	}
}