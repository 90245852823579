@import "../styles/Variables.scss";

.section-header {
	font-size: 1.3rem;
	font-weight: 700;
	margin-bottom: 25px;
	display: inline-block;
	
	span {
		color: $colour-font-main;
	}
	
	@media screen and (min-width: $breakpoint-lg) {
		font-size: 21px;
	}

	&.no-margin {
		margin: 0;
	}
}