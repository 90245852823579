@import "../styles/Variables.scss";

.fade-in-section {
	animation: fadeInSection 1s ease-in forwards;
	will-change: opacity, visibility;
	font-weight: inherit;
	font-size: inherit;
	
	&.fade-faster {
		animation-duration: 0.6s;
	}

	&.fade-slower {
		animation-duration: 1.3s;
	}

	&.fade-up {
		animation: fadeUpSection 0.8s ease-in forwards;
	}

	&.delay-1 {
		opacity: 0;
		animation-delay: 200ms;
	}

	&.delay-2 {
		opacity: 0;
		animation-delay: 400ms;
	
	}
	&.delay-3 {
		opacity: 0;
		animation-delay: 600ms;
	}

	@media screen and (min-width: $breakpoint-lg) {	
		p, .focus-text {
			// font-size: $fs-medium;
		}
	}
	
	@media screen and (min-width: $breakpoint-xl) {	
		p, .focus-text {
			// font-size: $fs-medium;
		}
	}
}

@keyframes fadeInSection {
	0% {
		opacity: 0;
		visibility: hidden;
	}

	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes fadeUpSection {
	0% {
		opacity: 0;
		visibility: hidden;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}