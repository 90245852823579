@import "../styles/Variables.scss";

.footer-container {
	background-color: $colour-footer;
	padding-bottom: 100px;
	height: $footer-height-xs;
	position: absolute;
	bottom: 0;
	left: 0;

	h2 {
		margin-bottom: 0;
		line-height: 1.6;
	}

	@media screen and (min-width: $breakpoint-md){
		height: $footer-height-md;
	}

	@media screen and (min-width: $breakpoint-lg){
		height: $footer-height-lg;
	}
}

.disclaimer {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
}

.MuiGrid-root {
	.icons-container {
		margin: 40px 0;
	}
}

.footer-icon {
	font-size: 14px;
	color: #fff;
	margin-right: 35px;

	&:last-of-type{
		margin-right: 0;
	}
}
