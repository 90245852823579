@import "../styles/Variables.scss";

.focus-paragraph {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.8;

	@media screen and (min-width: $breakpoint-lg) {	
		p {
			font-size: $fs-medium;
		}
	}
	
	@media screen and (min-width: $breakpoint-xl) {	
		p {
			font-size: $fs-large;
		}
	}

	a {
		.focus-text {
			color: $colour-blue;
		}
	}
}
