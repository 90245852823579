@import "../../../styles/Variables.scss";

.mobile-menu-container {
	position: fixed;
	right: -100vw;
	top: 0;
	background-color: #fff;
	height: 100vh;
	width: 100vw;
	z-index: 10;
	overflow: auto;
	transition: 300ms ease-in-out;

	&.menu-active {
		right: 0;
	}
}

.mobile-menu-header {
	padding-top: 47px;
	padding-bottom: 33px;
}

.mobile-menu-list {
	padding-top: 33px;
	padding-bottom: 38px;

	.link-list {
		padding-bottom: 38px;
		border-bottom: 1px solid $colour-divider;
	}

	.link-item {
		margin-bottom: 25px;
		font-weight: $fw-medium;
	}
}

.mm-icons-container {
	border-top: 1px solid $colour-divider;
	padding-top: 50px;

	.mm-icon {
		color: $colour-font-main;
		margin-right: 30px;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.close-menu-container {
	padding-top: 20px;
	padding-bottom: 20px;

	h6 {
		margin: 0;
	}
}