@import "../styles/Variables.scss";

.form-checkbox-group {
	display: flex;
	flex-wrap: wrap;
	
	.ts-form-checkbox {
		margin-right: 8px;
		margin-bottom: 10px;
	}

	.group-label {
		color: $colour-font-main;
		font-weight: $fw-medium;
		font-size: 17px;
		width: 100%;
	}
}