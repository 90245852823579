@import "../../../styles/Variables.scss";

.product-menu-item {
	display: flex;
	align-items: center;
	width: 450px;
	margin-bottom: 35px;

	&:last-of-type {
		margin-bottom: 0;
	}

	&:hover {
		.pmi-details {
			.pmi-name {
				color: $colour-blue;

				.pmi-arrow {
					transform: none;
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
}

.pmi-img {
	margin-right: 25px;

	img {
		width: 80px;
		height: auto;
	}
}

.pmi-name {
	font-weight: $fw-semi-bold;
	font-size: 16px;

	.pmi-arrow {
		color: $colour-blue;
		transform: translateX(-8px);
		visibility: hidden;
		opacity: 0;
		transition: 300ms ease-in-out;
	}
}


.pmi-info {
	font-weight: $fw-regular;
	font-size: 13px;
}