@import "../styles/Variables.scss";

.ts-form-checkbox {
	display: inline-flex;
	position: relative;
	cursor: pointer;
	padding: 8px 24px;
	background-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 2px solid $colour-blue;
	border-radius: 50px;
	transition: 300ms ease;

	// &:before {
	// 	content: "";
	// 	z-index: 0;
	// 	position: absolute;
	// 	left: 0;
	// 	height: 100%;
	// 	width: 10px;
	// 	top: 0;
	// 	background-color: $colour-blue;
	// 	transition: 200ms ease;
	// }

	span {
		z-index: 1;
		font-size: 12px;
		font-weight: $fw-medium;

		@media screen and (min-width: $breakpoint-md){
			font-size: 15px;
		}
	};
	
	/* Hide the browser's default checkbox */
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	
	&:hover, &:focus-within {
		@media screen and (min-width: $breakpoint-lg){
			color: $colour-blue;
	
			&:before {
				animation: stretch 300ms infinite alternate;
			}
		}

		&.checked {
			opacity: 1;
		}
	}

	&.checked {
		// padding: 8px 19px;
		background-color: $colour-blue;
		opacity: 1;

		span {
			color: #fff;
		}	

		&:before {
			width: 100% !important;
		}

		&:hover {
			&:before {
				animation: none;
			}
		}
	}
}

@keyframes stretch {
	0% {
		width: 10px;
	}

	100% {
		width: 14px;
	}
}