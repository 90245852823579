@import "./Variables.scss";

h1,h2,h3,h4,h5,h6 {
	font-weight: 700;
	margin: 0;
	margin-bottom: 20px;
	line-height: 1.2;
}

h1 {
	font-size: 34px;
	font-weight: 700;
}

h2 {
	font-size: 27px;
}

h3 {
	font-size: 23px;
	margin-bottom: 15px;
	font-weight: 700;
}

h4 {
	font-size: 20px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

p {
	font-size: 1rem;
	font-weight: 400;
	margin: 0;
	margin-bottom: 10px;
	line-height: 1.4;
}

@media screen and (min-width: $breakpoint-lg){
	h1 {
		font-size: 50px;
	}
	
	h2 {
		font-size: 27px;
	}
	
	h3 {
		font-size: 23px;
		margin-bottom: 15px;
	}
	
	h4 {
		font-size: 20px;
	}
	
	h5 {
		font-size: 18px;
	}
	
	h6 {
		font-size: 16px;
	}
	
	p {
		font-size: 1rem;
		font-weight: 400;
		margin: 0;
		margin-bottom: 10px;
		line-height: 1.4;
	}

	.sub-header {
		font-size: 20px;
	}
}

@media screen and (min-width: $breakpoint-xl){
	h1 {
		font-size: 65px;
	}
	
	h2 {
		font-size: 50px;
	}
	
	h3 {
		font-size: 36px;
		margin-bottom: 15px;
	}
	
	h4 {
		font-size: 23px;
	}
	
	h5 {
		font-size: 18px;
	}
	
	h6 {
		font-size: 16px;
	}
	
	p {
		font-size: 1rem;
		font-weight: 400;
		margin: 0;
		margin-bottom: 10px;
		line-height: 1.4;
	}

	.sub-header {
		font-size: 20px;
	}
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

small {
	font-size: smaller;
}

.colour-white {
	color: #fff;
}

.colour-blue {
	color: $colour-blue;
}

.small-text {
	font-size: $fs-small;
}

.font-weight-regular {
	font-weight: 400;
}

.font-weight-medium {
	font-weight: 500;
}

.font-weight-semi-bold {
	font-weight: 600;
}

.font-weight-bold {
	font-weight: 700;
}