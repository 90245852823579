@import "../styles/Variables.scss";

.error-header {
	@media screen and (min-width: $breakpoint-lg){
		font-size: 58px;
	}
}

.error-helper-text {
	font-size: 18px;
	font-weight: $fw-medium;

	a {
		&:hover {
			opacity: 0.3;
		}
	}

	@media screen and (min-width: $breakpoint-lg){
		font-size: 23px;
	}
}