// Colours
$colour-blue: #0C86F6;
$colour-blue-light: #B2CBFD;
$colour-blue-dark: #0B2E7C;
$colour-blue-alt: #5490FA;
$colour-footer: #031131;
$colour-bg: #fff;
$colour-blue-bg: #EFF3FF;
$colour-font-main: #02184A;
$colour-divider: #DBDBDB;
$colour-footer-bg: #F9FAFB;
$colour-error: #F5245C;

// Font
$font-primary: "quasimoda", sans-serif;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fs-small: 13px;
$fs-medium: 16px;
$fs-large: 24px;

// Breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// max widths
$max-small-size: 540px;
$max-medium-size: 720px;
$max-large-size: 900px;
$max-extra-large-size: 1140px;

// Absolute footer heights 
$footer-height-xs: 658px;
$footer-height-md: 608px;
$footer-height-lg: 500px;

// Misc
$border-radius: 20px;
$box-shadow: 0 2px 6px rgba(#000, 0.16);