@import "../styles/Variables.scss";

.ts-card {
	padding: 32px;
	border: none !important;
	box-shadow: 0 3px 6px rgba(#000, 0.16);

	&.more-button-card {
		display: flex;
    	align-items: center;
		justify-content: center;
   		background: transparent;
 	   	box-shadow: none;
		border: none;
	}
}
