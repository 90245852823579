@import "../styles/Variables.scss";

.underlined-text {
	font-size: inherit;
	font-weight: inherit;
	position: relative;
	z-index: 1;
	display: inline-block;
	color: $colour-blue;

	&::before {
		content: "";
		z-index: -1;
		width: 100%;
		display: none;
		position: absolute;
		bottom: 3%;
		left: 0;
		background-color: $colour-blue;
		opacity: 0.5;
		animation: animateBar 300ms ease-in forwards;
		animation-delay: 1s;
	}
}

@keyframes animateBar {
	0% {
		height: 0;
	}

	100% {
		height: 25%;
	}
}