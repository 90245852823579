@import "../styles/Variables.scss";

.modal-dialog {
	max-width: 700px;
}
.modal-content {
	background-color: #EFF3FF;
	border-radius: $border-radius;
	padding: 40px;
	border: none;
	position: relative;

	.close-modal-icon-wrapper {
		position: absolute;
		right: 28px;
		top: 28px;
		width: 32px;
		height: 32px;
		cursor: pointer;
		color: $colour-blue;
	}

	.modal-header {
		flex-direction: column;
		border: none;

		p {
			margin-bottom: 0;
		}
	}
	
	.modal-title {
		font-weight: 700;
		font-size: 40px;
	}
	
	.modal-footer {
		border: none;
		justify-content: flex-start;
	}

	.ts-input-field-wrapper {
		margin-bottom: 24px;
	}
}
