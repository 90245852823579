@import "../styles/Variables.scss";

.ts-container {
	padding-top: 50px;
	padding-bottom: 50px;
	overflow: hidden;

	&.bg-blue {
		background-color: $colour-blue-bg;
	}

	&.bg-white {
		background-color: $colour-blue-bg;
	}

	&.homepage-container {
		padding-top: 32px;
		padding-bottom: 32px;

		h2 {
			margin-bottom: 8px;
		}
	}

	@media screen and (min-width: $breakpoint-md){
		padding-top: 80px;
		padding-bottom: 80px;

		&.homepage-container {
			padding-top: 100px;
			padding-bottom: 100px;

			.container {
				max-width: 1300px;
			}
			
			h2 {
				margin-bottom: 10px;
			}
		}
	}

	@media screen and (min-width: $breakpoint-xl){
		padding-top: 80px;
		padding-bottom: 80px;

		&.homepage-container {
			padding-top: 100px;
			padding-bottom: 100px;

			.container {
				max-width: 1300px;
			}
		}
	}

	.hero-image-left {
		transition: 200ms ease-in-out;
		transform: translateX(-20%) scale(1.4);
		
		&:hover {
			transform: translateX(-20%) scale(1.42);
		}
	}

	.hero-image-right {
		transition: 200ms ease-in-out;
		transform: translateX(20%) scale(1.4);
		
		&:hover {
			transform: translateX(20%) scale(1.42);
		}
	}
}

