@import "../styles/Variables.scss";

.pfb-container {
	padding-top: 24px;
	padding-bottom: 24px;

	.pb-image {
		max-height: 100px;
		max-width: 200px;
		width: auto;
	}

	@media screen and (max-width: $breakpoint-sm){
		&:last-of-type {
			padding-bottom: 80px;
		}
	}

	@media screen and (min-width: $breakpoint-md){
		padding-top: 60px;
		padding-bottom: 60px;
	}
}