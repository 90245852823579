@import "../styles/Variables.scss";

.ts-button {
	width: auto;

	@media screen and (min-width: $breakpoint-lg){
		width: auto;
	}
	
	&.MuiButton-root {
		background-color: $colour-blue;
		font-family: $font-primary;
		border-radius: 8px;
		color: #fff;
		padding: 0 40px;
		height: 60px;
		min-width: 100px;
		border: none !important;
		transition: 200ms ease;
		font-size: 25px;
		letter-spacing: normal;

		&.colour-primary {
			span {
				color: $colour-blue;
			}
		}

		&.colour-white {
			span {
				color: $colour-blue;
			}
		}

		&.bg-colour-alt {
			// background-color: $colour-blue-alt;
			background-color: transparent;
		}

		span {
			color: #fff;
			text-transform: capitalize;
			font-weight: 600;
		}

		.button-arrow {
			transition: 300ms ease;
			transform: translateY(1px) translateX(2px);
			opacity: 1;
		}

		&:hover {
			background-color: $colour-blue;
			opacity: 0.6;

			.button-arrow {
				margin-left: 10px;
			}
		}
	}
	
	&.MuiButton-outlined {
		background-color: transparent;
		border-width: 2px;
		border-color: $colour-blue;
		border: none;

		&.bg-colour-alt {
			border-color: $colour-blue-alt;
		}

		&.bg-colour-white {
			border-color: #fff;
		}

		&:hover {
			background-color: transparent !important;
			
			&.bg-colour-alt {
				background-color: $colour-blue-alt;
			}
	
			&.bg-colour-white {
				background-color: #fff;
			}
		}
	}

	&.Mui-disabled {
		opacity: 0.5;
		background-color: $colour-blue;
	}

	.spinner-border {
		width: 1em;
		height: 1em;
		border-width: 0.15em;
		margin-right: 10px;
	}

	.MuiTouchRipple-root {
		display: none;
	}
}